.divider{
  .gradient {
    height: 20px;
    position: relative;
    width: 100%;
    background: radial-gradient(ellipse farthest-side at top center, rgba(0,0,0,.08), transparent);
    &:before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 3px;
      background: linear-gradient(to left, transparent, color2 = rgba(0,0,0,.02), color2 = rgba(0,0,0,.02), transparent);
    }
  }
  .shadow-box{
    text-align:center;
    padding:20px 0 20px 0;
    margin:0;
    box-shadow: 0 1em 1em -1em rgba(0, 0, 0, .25);
  }
  .centred-text{
    display: table;
    font-size: 24px;
    text-align: center;
    width: 75%;
    margin: 40px auto;
    span {
      display: table-cell;
      position: relative;
    }
    span:first-child, &span:last-child{
      width: 50%;
      top: 13px;
      -moz-background-size: 100% 2px;
      background-size: 100% 2px;
      background-position: 0 0, 0 100%;
      background-repeat: no-repeat;
    }
    span:first-child{
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(#000));
      background-image: -webkit-linear-gradient(180deg, transparent, #000);
      background-image: -moz-linear-gradient(180deg, transparent, #000);
      background-image: -o-linear-gradient(180deg, transparent, #000);
      background-image: linear-gradient(90deg, transparent, #000);
    }
    span:nth-child(2){
      color: #000;
      padding: 0 5px;
      width: auto;
      white-space: nowrap;
    }
    span:last-child{
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#000), to(transparent));
      background-image: -webkit-linear-gradient(180deg, #000, transparent);
      background-image: -moz-linear-gradient(180deg, #000, transparent);
      background-image: -o-linear-gradient(180deg, #000, transparent);
      background-image: linear-gradient(90deg, #000, transparent);
    }
  }
  .header-underline{
    border-bottom: 1px solid #FFF;
    background-color: #DADADA;
    height: 1px;
    margin: 0.5em 0 1.5em;
    span{
      display: block;
      width: 50px;
      height: 1px;
      background-color: $theme-color;
    }
  }
  .arrow-down{
    position: relative;
    margin-top: 90px;
    height: 1px;
    &.transparency{
      content: "";
      position: absolute;
      top: 0;
      left: 5%;
      right: 5%;
      width: 90%;
      height: 1px;
      background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
    }
    &.arrow{
      content: "";
      position: absolute;
      z-index: 1;
      top: -7px;
      left: calc(50% - 7px);
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      background-color: white;
      border-bottom: 1px solid rgb(48,49,51);
      border-right: 1px solid rgb(48,49,51);
    }
  }
}
