.btn {
  &.btn-primary{
    color: #fff;
    background-color: $theme-color;
    border-color: $theme-color;
    &:hover{
      background-color: $theme-light;
      border-color: $theme-light;
    }
  }
}